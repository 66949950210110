import React from 'react';
import { StaticQuery, graphql, withPrefix } from "gatsby";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import HtmlMap from '../../components/HtmlMap';
import { Formik } from 'formik';
import $ from 'jquery';
import * as Yup from 'yup';
import Header from '../../components/header';
import ListGroup from 'react-bootstrap/ListGroup'
import ContactInfo from  '../../components/contactData'
import FormG from '../../components/input'

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ble = Yup.object({
  firstName: Yup.string()
    .min(3, 'Имя дольжна содержать мин. три знака.')
    .required('требуется'),
  lastName: Yup.string()
    .min(3, 'Фамилия дольжна содержать мин. три знака.')
    .required('требуется'),
  company: Yup.string()
    .min(3, 'Название фирмы дольжно содержать мин. три знака.')
    .required('требуется'),
  email: Yup.string()
    .email('Ошибочная электронная почта.'),
  phone: Yup.string()
    .matches(phoneRegEx,'Ошибочный номер телефона.')
    .required('требуется'),
});
const initialV = {firstName: '', lastName: '',company:'', email: '', phone:''};
const ionsTab =[
        ['fas','dollar-sign'],
        ['fas','envelope'],
        ['fas','map-marker-alt'],
        ['fas','mobile-alt'],
        ['fas','phone-square-alt']
      ]

const Contact = ({dataPack, lang, headerD = dataPack.header.edges[0].node.headerData[3]}) => (
      <section className={`${headerD.headerSection}`} id={`${headerD.headerLink}`}>

                <Header
                  siteTitle={headerD.headerName[lang]}
                  imgBg={headerD.headerImage.childImageSharp.fluid}
                  imgLogo={headerD.headerLogo.childImageSharp.fluid}
                  imgBgAlt={headerD.headerImgAlt[lang]}
                  imgLogoAlt={headerD.headerLogoAlt[lang]}
                  />

          <Container fluid className="no-padding backGround">
            <Row noGutters>
            <Col md={1} className="leftBar d-md-block d-sm-none"></Col>
              <Col md={6} className="px-3">

                  <Alert variant="success" id="infoBoxS" className="my-2">
                    <Alert.Heading>{dataPack.infoBox.edges[0].node.contactPage[0].emailSend[lang]}</Alert.Heading>
                  </Alert>
                  <Alert variant="danger" id="infoBoxD" className="my-2">
                    <Alert.Heading>{dataPack.infoBox.edges[0].node.contactPage[0].emailFail[lang]}</Alert.Heading>
                  </Alert>

              <Formik
                  initialValues={initialV}
                  validationSchema={ble}
                  onSubmit={(values, { setSubmitting }) => {
                    values['text'] = $('#controlTextarea').val();

                    $.ajax({
                      data:values,
                      type:'POST',
                      url:withPrefix('/sendMail.php'),
                      success:function(data){
                          console.info(this.data);
                          $('#infoBoxS').fadeIn();
                          $('#infoBoxS').show();


                      },
                      error:function(xhr,status,err){
                          console.error(status, err.toString())
                          $('#infoBoxD').fadeIn();
                          $('#infoBoxD').show();
                      }
                    });
                    setSubmitting(false);
                }}
                  >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    isSubmitting,
                  })  => (               <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>

                      <FormG
                        controlId = "validationFormik01"
                        name = {dataPack.infoBox.edges[0].node.contactPage[0].name[lang]}
                        typeC = "text"
                        nameC = "firstName"
                        valueC = {values.firstName}
                        onChangeC = {handleChange}
                        isTouchedC = {touched.firstName}
                        isErrorC = {errors.firstName}
                        isOk = {dataPack.infoBox.edges[0].node.contactPage[0].dataOk[lang]}
                        colNo = {6}
                        />
                      <FormG
                        controlId = "validationFormik02"
                        name = {dataPack.infoBox.edges[0].node.contactPage[0].forName[lang]}
                        typeC = "text"
                        nameC = "lastName"
                        valueC = {values.lastName}
                        onChangeC = {handleChange}
                        isTouchedC = {touched.lastName}
                        isErrorC = {errors.lastName}
                        isOk = {dataPack.infoBox.edges[0].node.contactPage[0].dataOk[lang]}
                        colNo = {6}
                        />

                    </Form.Row>
                    <Form.Row>

                      <FormG
                        controlId = "validationFormik03"
                        name = {dataPack.infoBox.edges[0].node.contactPage[0].company[lang]}
                        typeC = "text"
                        nameC = "company"
                        valueC = {values.company}
                        onChangeC = {handleChange}
                        isTouchedC = {touched.company}
                        isErrorC = {errors.company}
                        isOk = {dataPack.infoBox.edges[0].node.contactPage[0].dataOk[lang]}
                        colNo = {12}
                        />

                    </Form.Row>

                                          <Form.Row>

                                            <FormG
                                              controlId = "validationFormik04"
                                              name = {dataPack.infoBox.edges[0].node.contactPage[0].email[lang]}
                                              typeC = "text"
                                              nameC = "email"
                                              valueC = {values.email}
                                              onChangeC = {handleChange}
                                              isTouchedC = {touched.emial}
                                              isErrorC = {errors.email}
                                              isOk = {dataPack.infoBox.edges[0].node.contactPage[0].dataOk[lang]}
                                              colNo = {6}
                                              />
                                            <FormG
                                              controlId = "validationFormik05"
                                              name = {dataPack.infoBox.edges[0].node.contactPage[0].phone[lang]}
                                              typeC = "text"
                                              nameC = "phone"
                                              valueC = {values.phone}
                                              onChangeC = {handleChange}
                                              isTouchedC = {touched.phone}
                                              isErrorC = {errors.phone}
                                              isOk = {dataPack.infoBox.edges[0].node.contactPage[0].dataOk[lang]}
                                              colNo = {6}
                                              />

                                          </Form.Row>
                                          <Form.Row>

                                            <Form.Group as={Col} xs="12" controlId="controlTextarea">
                                              <Form.Label>{dataPack.infoBox.edges[0].node.contactPage[0].message[lang]}</Form.Label>
                                              <Form.Control
                                                as="textarea"
                                                name="message"
                                                rows="3"
                                              />
                                            </Form.Group>

                                          </Form.Row>

                                          <Button type="submit" disabled={isSubmitting}>{dataPack.infoBox.edges[0].node.contactPage[0].sendButton[lang]}</Button>
                                        </Form>
                              )}
                            </Formik>


              </Col>
              <Col md={4} className="text-center px-sm-3 px-md-0 mt-3 align-content-center">
                <ContactInfo icons={ionsTab} lang={lang+1} langNo={4} />

              </Col>
              <Col md={1}  className="d-md-block d-sm-none"></Col>
            </Row>
          </Container>
          <Container fluid className="no-padding">
            <Row noGutters>
              <Col xs={12} className="map px-md-0 px-sm-3 px-5 mb-3">
                <HtmlMap name="Цветочный магазин Алма" street="ул.Krucza 31b" city="53-409 Вроцлав"></HtmlMap>
              </Col>
            </Row>
          </Container>


    </section>
  )

export default props =>(
<StaticQuery query={graphql`
  query contactQueryRU{
    header:allJson(sort: {fields: headerData}, limit: 1) {
      edges {
        node {
          headerData{
              headerName
              headerLink
              headerSection
              headerImgAlt
              headerLogoAlt
              headerImage{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              headerLogo{
                childImageSharp{
                  fluid(quality: 100){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
    infoBox:allJson(sort: {fields: contactPage}, limit: 1) {
      edges {
        node {
          contactPage{
              dataOk
              emailSend
              emailFail
              name
              forName
              company
              message
              email
              phone
              sendButton
          }
        }
      }
    }
  }
`}
render={data=><Contact dataPack={data}{...props} />}
/>
)
